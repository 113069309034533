import React, { FC, PropsWithChildren } from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";

const StyledContainer = styled(Container)`
  margin-bottom: 40px;
`;

const SectionContent: FC<PropsWithChildren> = ({ children }) => {
  return (
    <StyledContainer
      fluid
      id="main-content"
      className="p-3 bg-pro-light-grey"
    >
      {children}
    </StyledContainer>
  );
};

export default SectionContent;
